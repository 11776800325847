import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./ProductDetail.css";
import Nav from "../Visual/Nav";
import ProductImageViewer from "./ProductImageViewer";

import Footer from "../Visual/Footer";

const ProductDetail = () => {
  const location = useLocation();
  const { product } = location.state || {};

  console.log(product.type);

  const [shippingOption, setShippingOption] = useState("");
  const [showShippingOption, setShowShippingOption] = useState("");
  const [stock, setStock] = useState(parseInt(product.stock) || 1); // Assuming product has a stock property
  const [timeWaiting, setTimeWaiting] = useState(-1);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [canCheckOut, setCanCheckOut] = useState(true);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [checkoutLink, setCheckoutLink] = useState(null);
  const [stockChecked, setStockChecked] = useState(false);
  const [loading, setLoading] = useState(false); // State for loading

  const handleShippingChange = (event) => {
    setShippingOption(event.target.value);
  };
  useEffect(() => {
    if (timeWaiting === -1) return;

    const expiresAt = timeWaiting * 1000; // Convert to milliseconds
    const updateCountdown = () => {
      const now = new Date().getTime();
      const distance = expiresAt - now;

      if (distance < 0) {
        clearInterval(interval);
        setCanCheckOut(true);
        setTimeRemaining("You can try to purchase again now.");
        return;
      }

      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimeRemaining(`${minutes}m ${seconds}s`);
    };

    const interval = setInterval(updateCountdown, 1000);
    updateCountdown(); // Initial call to set the countdown immediately

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [timeWaiting]);

  async function getShippings() {
    //https://k8availabilityhandler.andrewdouglas004.workers.dev/
    //http://127.0.0.1:8787
    const response = await fetch("https://k8availabilityhandler.andrewdouglas004.workers.dev/", {
      method: "POST",
      body: JSON.stringify({
        shippingID: product.shippingLink[0].value,
        shippingIndex: product.shippingLink[0].id,
        requestType: "getShippings",
      }),
    });

    var responseData = await response.json();

    if (responseData.isUk && responseData.isEurope) {
      setShowShippingOption("both");
    }

    else if (responseData.isUk) {
      setShowShippingOption("uk");
    }

    else if (responseData.isEurope) {
      setShowShippingOption("europe");
    }
  }

  useEffect(() => {
    getShippings();
  }, []); // Empty dependency array ensures this runs once on component mount

  if (!product) {
    return <div>Loading...</div>;
  }
  


  async function addToCart() {
    setLoading(true); // Stop loading

    const response = await fetch("https://k8availabilityhandler.andrewdouglas004.workers.dev/", {
      method: "POST",
      body: JSON.stringify({
        requestType: "checkout",
        product: product.UUID,
        shippingID: product.shippingLink[0].value,
        shippingIndex: product.shippingLink[0].id,
        shippingOption: shippingOption,
      }),
    });

    var responseData = await response.json();

    if(responseData.available == false && !responseData.expiresAt){
      setStock(0);
      setLoading(false); // Stop loading
      return;
    }

    if(responseData.available == false && responseData.expiresAt){
      setCanCheckOut(false);
      setLoading(false); // Stop loading
      setTimeWaiting(responseData.expiresAt);
      return;
    }

    if (responseData.link) {
      // Create an anchor element
      // const anchor = document.createElement('a');
      // anchor.href = responseData.link;
      // anchor.target = '_blank';
      // anchor.rel = 'noopener noreferrer'; // Security measure
  
      // // Append the anchor to the body
      // document.body.appendChild(anchor);
  
      // // Trigger a click event on the anchor
      // anchor.click();
  
      // // Remove the anchor from the body
      // document.body.removeChild(anchor);

      if (responseData.link) {
        // Store the link in state to be used in a user-initiated event
        setCheckoutLink(responseData.link);
        setStockChecked(true); // Mark stock check as complete
        setLoading(false); // Stop loading
        setTimeout(() => {
          setStockChecked(false);
          setCheckoutLink(null);
      }, 5 * 60 * 1000); // Reset after 5 minutes
      }
  }
  }

  const handleCheckoutClick = () => {
    if (checkoutLink) {
      // Create an anchor element
      const anchor = document.createElement('a');
      anchor.href = checkoutLink;
      anchor.target = '_blank';
      anchor.rel = 'noopener noreferrer'; // Security measure
  
      // Append the anchor to the body
      document.body.appendChild(anchor);
  
      // Trigger a click event on the anchor
      anchor.click();
  
      // Remove the anchor from the body
      document.body.removeChild(anchor);
  
      // Clear the checkout link state
      setCheckoutLink(null);
    }
  };

  return (
    <div>
      <Nav />
      <div className="product-detail-base">
        <div className="product-image-description">
          <div className="product-image-viewer">
            <ProductImageViewer
              images={[
                product.img1[0],
                product.img2[0],
                product.img3[0],

              ]}
            />

{/* <div className="product-image-viewer">
    <img src={product.img1[0].url} alt="Product" className="product-image" />
    <div className="thumbnail-strip">
        <img
            src={product.img1[0].url}
            alt="Thumbnail 1"
            className="thumbnail"
        />
        <img
            src={product.img2[0].url}
            alt="Thumbnail 2"
            className="thumbnail"
        />
    </div>
</div> */}


          </div>
          <div className="description">
          <h1>{product.name}</h1>
            {/* <h2>Description</h2> */}
            <p className = "descriptionMain">{product.description}</p>
            <p>Shipping is calculated at checkout</p>
            <p className="price">£{product.price}</p>
            <div>
            {!canCheckOut ? (
              <>
            <p>Looks like another user is checking out with this item: </p>
            <p>Time untill user's checkout expires:</p>
            <p>{timeRemaining}</p>
            </>
            ) : null}

        </div>
            <div>
            {stock > 0 && canCheckOut ? (
  <>
    {showShippingOption && (
      <div>
        {(showShippingOption === "uk" || showShippingOption === "both") && (
            <div className="shipping-option">
          <label>
            <input
              type="radio"
              value="uk"
              checked={shippingOption === "uk"}
              onChange={handleShippingChange}
            />
            Ship to UK
            
          </label>
        </div>
        )}
{/* {(showShippingOption === "europe" || showShippingOption === "both") && (
  <div className="shipping-option">
    <label>
      <input
        type="radio"
        value="europe"
        checked={shippingOption === "europe"}
        onChange={handleShippingChange}
      />
      Ship to Europe
    </label>
  </div>
)} */}
      </div>
    )}
{shippingOption && (
    <>
        <div className="terms-and-conditions">
            <input 
                type="checkbox" 
                id="terms" 
                checked={agreedToTerms} 
                onChange={() => setAgreedToTerms(!agreedToTerms)} 
            />
            <label htmlFor="terms">I agree to the terms and conditions</label>
        </div>
        {agreedToTerms && (
                        <div>
                            {!stockChecked && (
                                loading ? (
                                    <div className="loading">Loading...</div> // Loading symbol
                                ) : (
                                    <button className="add-to-cart" onClick={addToCart}>
                                        Check Stock
                                    </button>
                                )
                            )}
                            {stockChecked && checkoutLink && (
                                <button className="checkoutButton" onClick={handleCheckoutClick}>
                                    Proceed to Checkout
                                </button>
                            )}
                        </div>
                    )}
    </>
)}
  </>
) : (
  stock === 0 && <h2>Out of Stock</h2>
)}

            </div>
          </div>
        </div>
        {product.type === "Clothing" && (
                <div className="productSideBySide">
                    <div className="productwhyk8">
                        <h2>Why buy K8?</h2>
                        <p>
                            I've always been deeply passionate about creating sustainable and
                            eco-friendly clothing. Each piece in my collection is meticulously
                            hand-sewn, reflecting my commitment to craftsmanship and
                            environmental responsibility. By choosing to hand-sew every item, I
                            ensure that each garment is unique and made with the utmost care and
                            attention to detail. My dedication to sustainability goes beyond
                            just the materials; it encompasses the entire process, from design
                            to production. This approach not only reduces waste but also
                            supports a more ethical and mindful fashion industry.
                        </p>
                    </div>

                    <div className="how-to-care">
                        <h2>How to care for your K8 garment</h2>
                        <p>
                            To ensure that your K8 garment remains in excellent condition,
                            please follow these care instructions:
                        </p>
                        <ul>
                            <li>Hand wash in cold water with a gentle detergent</li>
                            <li>Do not bleach</li>
                            <li>Lay flat to dry</li>
                            <li>Iron on low heat if necessary</li>
                        </ul>
                    </div>
                </div>
            )}
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetail;